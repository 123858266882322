<!--
 * @Author: zhangyutong
 * @Date: 2021-08-30 15:45:06
 * @LastEditTime: 2022-04-13 18:03:37
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingPosters\component\dialogFrom.vue
-->
<!--  -->
<template>
  <div class="">
    <el-form
      :status-icon="true"
      ref="fromFormat"
      :model="posterFromData"
      class="clearfix"
      :validate-on-rule-change="false"
      label-position="right"
      :rules="rules"
    >
      <!-- 新建 -->
      <div style="height: '500px',overflow: 'auto',padding: '0 20px'">
        <div class="pull-left" style="width: 100%">
          <!-- 基本信息 -->
          <p class="groupTitle">{{$t('page.layout.binfo')}}</p>
        </div>
        <div class="input-box">
          <!-- 海报渠道名称 -->
          <el-form-item
            :label="$t('c734')"
            label-width="200px"
            style="min-height: 38px; width: 100%"
            prop="name"
          >
            <el-input
              v-model="posterFromData.name"
              size="mini"
              style="width: 380px"
            ></el-input>
            <!-- 名称不可重复 -->
            <span class="prompt ml-10">{{$t('c735')}}</span>
          </el-form-item>
          <!-- 实际成本 -->
          <el-form-item
            :label="$t('label.campagin.roi.sjcb')"
            label-width="200px"
            style="min-height: 38px; width: 100%"
          >
            <el-input
              v-model="posterFromData.actualcost"
              size="mini"
              style="width: 380px"
            ></el-input>
          </el-form-item>
          <!-- 选择该海报应用的表单 -->
          <el-form-item
            :label="$t('c427')"
            label-width="200px"
            style="min-height: 38px; width: 100%"
            prop="formid"
          >
            <!-- 查找/查找多选 -->
            <el-select
              v-model="posterFromData.formid"
              filterable
              :placeholder="$t('label.searchs')"
              clearable
              class="no-suffix"
              style="width: 50%"
              @blur="blurEvent"
            >
              <p class="searchTipBox">
                <span
                  @click="remoteFromSearch()"
                  style="display: inline-block; width: 100%"
                >
                  <i class="el-icon-search"></i>
                  <span style="padding-left: 10px">
                    {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                  </span>
                </span>
              </p>
              <el-option
                v-for="item in optionFromList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-option
                v-show="false"
                key="new"
                :label="$t('label.new')"
                value="新建"
              >
              </el-option>
              <p class="searchTipBox" @click="newSearchableFromField()">
                <i class="el-icon-plus"></i>
                <span style="padding-left: 10px">
                  {{ $t("label.new") }}
                </span>
              </p>
            </el-select>
          </el-form-item>
          <!-- 市场活动所有人 -->
          <el-form-item
            :label="$t('label.all.marketing.activities')"
            label-width="200px"
            style="min-height: 38px; width: 100%"
            prop="campaignownerid"
          >
            <!-- 查找/查找多选 -->
            <el-select
              v-model="posterFromData.campaignownerid"
              filterable
              :placeholder="$t('label.searchs')"
              clearable
              class="no-suffix"
              style="width: 50%"
              @blur="blurEvent"
            >
              <p class="searchTipBox">
                <span
                  @click="remoteSearch()"
                  style="display: inline-block; width: 100%"
                >
                  <i class="el-icon-search"></i>
                  <span style="padding-left: 10px">
                    {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                  </span>
                </span>
              </p>
              <el-option
                v-for="item in optionUserList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-option
                v-show="false"
                key="new"
                :label="$t('label.new')"
                value="新建"
              >
              </el-option>
              <!-- <p class="searchTipBox" @click="newSearchableField()">
                <i class="el-icon-plus"></i>
                <span style="padding-left: 10px">
                  {{ $t("label.new") }}
                </span>
              </p> -->
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div style="text-align: right">
        <el-form-item>
          <el-button size="mini" type="primary" @click="complete('fromFormat')">
            {{ $t("UG.Forcast.10") }}
          </el-button>
        </el-form-item>
      </div>
      <!-- share -->
      <el-dialog
        title="Share your form"
        :visible.sync="sharetype"
        width="587px"
        custom-class="dialog"
      >
        <div class="sharecontent">
          <el-tabs v-model="shareName" @tab-click="handleClicked">
            <el-tab-pane label="Share link" name="second"></el-tab-pane>
            <el-tab-pane label="QR code" name="third"></el-tab-pane>
          </el-tabs>
          <div class="embcode" v-show="Ecodeshow">
            <p>
              To embed this form,simply copy and paste the code below into the
              HTML code on your website.
              <span
                >Leam more
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-share"></use>
                </svg></span
              >
            </p>
            <div class="embcontent">
              <p>{{ vhtml }}</p>
            </div>
          </div>
          <div class="Slink" v-show="Slinkshow">
            <p>
              Start sharing your form with contacts,Your form has been securely
              puvlished and ready to use at the web address below.
            </p>
            <div class="slinkcontent">
              <input type="text" /><button>Copy</button>
            </div>
            <span
              >Preciew link in new tab
              <svg class="icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg></span
            >
          </div>
          <div class="QRcode" v-show="QRcodeshow">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-qrcode-demo"></use>
            </svg>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="sharetype = false">cancel</el-button>
          <el-button type="primary" @click="sharetype = false">Copy</el-button>
        </div>
      </el-dialog>
      <!-- 搜索弹窗 -->
      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @setFieldReltaion="setFieldReltaion"
          @changeSelect="changeSlaveSelect"
        />
      </el-dialog>
      <!--from 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        :visible.sync="showFromSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <searchFromTable
          ref="searchFromTable"
          @changeSelect="changeSlaveFromSelect"
        />
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import * as CommonObjApi from "../../api";
import searchFromTable from "@/views/marketList/marketingPosters/component/searchFromTable.vue";
import searchTable from "@/components/Form/search-table.vue";

export default {
  components: { searchFromTable, searchTable },
  data() {
    return {
      rules: {
        formid: [
          {
            required: true,
            message: this.$i18n.t("label.batchadd.newbatchpage.save.emptytext"),
            trigger: "blur",
          },
        ],
        campaignownerid: [
          {
            required: true,
            message: this.$i18n.t("label.batchadd.newbatchpage.save.emptytext"),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$i18n.t("label.batchadd.newbatchpage.save.emptytext"),
            trigger: "blur",
          },
        ],
      },
      posterFromData: {
        formid: "",
        name: "",
        campaignownerid: "",
        actualcost: "",
        id: "",
      },
      //市场所有人id
      showFromSearchTable: false,
      showSearchTable: false,
      //搜索弹窗
      fieldId: "ffeposterfields003",
      checked: false,
      relevantPrefix: "005",
      relevantObjId: "poster",
      optionFromList: [],
      optionUserList: [],
    };
  },
  //方法集合
  methods: {
    init(id) {
      if (id) {
        CommonObjApi.getPosterInfo({ id: id }).then((res) => {
          if (res.result) {
            this.posterFromData = res.data.posterList[0];
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      } else {
        this.posterFromData = {
          formid: "",
          name: "",
          actualcost: "",
          campaignownerid: "",
          id: "",
        };
        this.optionUserList = [];
        this.getuser();
        this.$refs.fromFormat.resetFields();
      }
    },
    //获取市场活动所有人
    getuser() {
      CommonObjApi.getDetail({
        id: this.$route.params.id,
        operation: "DETAIL",
      }).then((res) => {
        let list = res.data.dataList[0].data[0].right.data;
        list.forEach((item) => {
          let obj = {};
          obj.label = item.value;
          obj.value = item.id;
          this.optionUserList.push(obj);
        });

        this.posterFromData.campaignownerid = this.optionUserList[0].value;
      });
    },
    // 表单选项新建
    newSearchableFromField() {
      this.$router.push({
        path: "/marketForm/selectTemplate",
      });
    },
    // 市场活动人员新建
    newSearchableField() {},
    blurEvent() {},
    handleClicked(tab) {
      if (tab.index === "0") {
        this.Slinkshow = true;
        this.QRcodeshow = false;
      } else {
        this.Slinkshow = false;
        this.QRcodeshow = true;
      }
    },
    complete(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.posterFromData.campaignid = this.$route.params.id;
          CommonObjApi.savePoster(this.posterFromData).then((res) => {
            if (res.result) {
              this.$message({
                type: "success",
                message: this.$i18n.t("label.companyprofile.syncsuccess"),
              });
              this.$emit("close");
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 查找/查找多选
    remoteFromSearch() {
      this.showFromSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchFromTable.page = 1;
        this.$refs.searchFromTable.pageObj.dataList = [];
        this.$refs.searchFromTable.init();
      });
    },
    changeSlaveFromSelect(row) {
      this.showFromSearchTable = false;
      if (row.data) {
        this.optionFromList.push({
          label: row.data.subject,
          value: row.data.id,
        });
        this.optionFromList = this.norepeat(this.optionFromList);
        this.posterFromData.formid = row.data.id.toString();
      } else {
        this.optionFromList = [];
        this.posterFromData.formid = "";
      }
    },
    // 改变从对象查找/查找带值选项及值
    changeSlaveSelect(row) {
      this.showSearchTable = false;

      if (row.constructor == Array) {
        this.optionUserList = [];
        this.posterFromData.campaignownerid = "";
      } else {
        this.optionUserList.push({
          label: row.data.name,
          value: row.data.id,
        });
        this.optionUserList = this.norepeat(this.optionUserList);
        this.posterFromData.campaignownerid = row.data.id.toString();
      }
    },
    norepeat(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].value == arr[j].value) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    // 设置查找筛选条件
    setFieldReltaion() {
      // let values = []
      // let dataId =
      //   this.dataLists[this.ind][this.rowIndex - 1].id === undefined
      //     ? ''
      //     : this.dataLists[this.ind][this.rowIndex - 1].id.value
      // values.push(dataId)
      // for (let reltaion in filterFieldIds) {
      //   this.$refs.EditableCell.forEach((editableCell) => {
      //     if (
      //       editableCell.lineIndex === this.rowIndex - 1 &&
      //       editableCell.fieldId === filterFieldIds[reltaion].fieldid
      //     ) {
      //       if (Array.isArray(editableCell.editValue)) {
      //         values.push(editableCell.editValue.join(';'))
      //       } else {
      //         values.push(editableCell.editValue)
      //       }
      //     }
      //   })
      // }
      // this.$refs.searchTable.filterConditionVals = values
    },
    // 查找/查找多选
    remoteSearch() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  &:hover {
    background: #f5f7fa;
  }
}

::v-deep .no-suffix .el-input__suffix {
  display: none;
}
.sharecontent {
  width: 94.6%;
  margin: 2.7%;
  .sharehead {
    width: 100%;
    height: 111px;
    background: #fef8f0;
    border: 1px solid #ffdfbb;
    border-radius: 1px;
    font-family: MicrosoftYaHei-Bold;
    padding-left: 20px;
    h1 {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: bolder;
      line-height: 35px;
      span {
        font-size: 14px;
        color: #151515;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: normal;
      }
    }
  }
  .embcode {
    width: 100%;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 15px;
      span {
        font-size: 14px;
        color: #006dcc;
        letter-spacing: 0;
        line-height: 19px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .embcontent {
      width: 100%;
      height: 163px;
      border: 1px solid #dedcda;
      background: rgb(157, 219, 209);
    }
  }
  .Slink {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    .slinkcontent {
      width: 100%;
      margin-top: 14px;
      input {
        width: 85%;
        height: 40px;
        outline: none;
        border: 1px solid #dedcda;
        border-radius: 1px;
        border-right: none;
        padding-left: 13px;
      }
      button {
        width: 15%;
        height: 42px;
        border-left: none;
        outline: none;
        background: #006dcc;
        border-radius: 1px;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        cursor: pointer;
        border: 1px solid #dedcda;
      }
    }
    span {
      display: inline-block;
      margin-top: 19px;
      color: #006dcc;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: bolder;
    }
  }
  .QRcode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 154px;
      height: 154px;
      margin-top: 54px;
      margin-bottom: 54px;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 12px;
}

::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
  width: 100%;
}
.input-box {
  padding-top: 80px;
  .prompt {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #6d7278;
  }
}
</style>