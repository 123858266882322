<!--
 * @Author: zhangyutong
 * @Date: 2021-09-13 13:50:14
 * @LastEditTime: 2022-03-30 11:45:18
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingPosters\component\shareFrom.vue
-->
<!--  -->
<template>
  <div class="">
    <!-- 分享 -->
    <el-dialog
      :title="title"
      :visible.sync="sharetype"
      width="587px"
      custom-class="dialog"
    >
      <div class="sharecontent">
        <el-tabs v-model="shareName" @tab-click="handleClicked">
          <el-tab-pane
            :label="$t('label.share_link')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane :label="$t('label.qrcode')" name="third"></el-tab-pane>
        </el-tabs>
        <div class="Slink" v-show="Slinkshow">
          <div class="slinkcontent">
            <input type="text" v-model="urlInput" /><button
              size="mini"
              @click="copyText(urlInput)"
            >
              {{ $t("pagecreator.page.button.copy") }}
            </button>
          </div>
        </div>
        <div class="QRcode" v-show="QRcodeshow">
          <img
            class="codeImg"
            :src="qrcodeUrl"
            :alt="$t('label.ems.error.fail')"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sharetype = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="sharetype = false">{{
          $t("pagecreator.page.button.copy")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {copyText} from "@/utils/copyInfo"
import * as marketChannel from '@/views/marketList/api.js'
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "xx"
    }
  },
  data() {
    return {
      urlInput: '',
      Slinkshow: true,
      QRcodeshow: false,
      shareName: 'second',
      sharetype: false,
      qrcodeUrl: null
    };
  },
  //方法集合
  methods: {
    copyText,
    handleClicked(tab) {
      if (tab.index === "0") {
        this.Slinkshow = true
        this.QRcodeshow = false
      } else {
        this.Slinkshow = false
        this.QRcodeshow = true
      }
    },
    init(id) {
      this.sharetype = true
      marketChannel.getPosterShareInfo({ id: id }).then(res => {
        if (res.result && res.data) {
          this.urlInput = res.data.url
          this.qrcodeUrl = res.data.qrcodeUrl
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          })
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
.sharecontent {
  width: 94.6%;
  margin: 2.7%;
  .sharehead {
    width: 100%;
    height: 111px;
    background: #fef8f0;
    border: 1px solid #ffdfbb;
    border-radius: 1px;
    font-family: MicrosoftYaHei-Bold;
    padding-left: 20px;
    h1 {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: bolder;
      line-height: 35px;
      span {
        font-size: 14px;
        color: #151515;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: normal;
      }
    }
  }
  .embcode {
    width: 100%;
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 15px;
      span {
        font-size: 14px;
        color: #006dcc;
        letter-spacing: 0;
        line-height: 19px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .embcontent {
      width: 100%;
      height: 163px;
      border: 1px solid #dedcda;
      background: rgb(157, 219, 209);
    }
  }
  .Slink {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    .slinkcontent {
      width: 100%;
      margin-top: 14px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input {
        width: 85%;
        height: 40px;
        outline: none;
        border: 1px solid #dedcda;
        border-radius: 2px;
        padding-left: 13px;
        border-right: none;
      }
      button {
        float: right;
        width: 15%;
        height: 46px;
        border-left: none;
        outline: none;
        background: #006dcc;
        border-radius: 2px;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        cursor: pointer;
        border: 1px solid #dedcda;
      }
    }
    span {
      display: inline-block;
      margin-top: 19px;
      color: #006dcc;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: bolder;
    }
  }
  .QRcode {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .codeImg {
      width: 154px;
      height: 154px;
      margin-top: 54px;
      margin-bottom: 54px;
    }
  }
}
</style>