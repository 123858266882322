import { render, staticRenderFns } from "./marketingPostersListShow.vue?vue&type=template&id=0f213ece&scoped=true&"
import script from "./marketingPostersListShow.vue?vue&type=script&lang=js&"
export * from "./marketingPostersListShow.vue?vue&type=script&lang=js&"
import style0 from "./marketingPostersListShow.vue?vue&type=style&index=0&id=0f213ece&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f213ece",
  null
  
)

export default component.exports